<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <v-col cols="12">
      <app-card-code
        :title="`${$t('menu.products')} / ${$t('menu.hotels')} / ${$t('lbl.cargaLote')} / ${$t('menu.servicios')}`"
      >
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="$router.push({ name: 'hotels-upload-lote' })"
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
          </v-row>

          <v-form class="multi-col-validation">
            <v-row v-if="lote !== null">
              <fragment v-if="lote !== null">
                <v-col
                  cols="12"
                  md="8"
                  offset-md="2"
                >
                  <v-simple-table>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-uppercase">
                            {{ $t('lbl.service') }}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr
                          v-for="(item, index) in lote.lote[posItem].servicios"
                          :key="index"
                        >
                          <fragment v-if="!item.listo">
                            <td>
                              {{ item.name }}
                              <v-tooltip
                                v-if="item.id === 0 && item.name !== ''"
                                top
                                color="error"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    @click="modalServicio(item, index)"
                                  >
                                    <v-icon color="error">
                                      {{ icons.mdiInformationOutline }}
                                    </v-icon>
                                  </v-btn>
                                </template>
                                <span>{{ $t('lbl.inexistServicio') }}</span>
                              </v-tooltip>
                            </td>
                          </fragment>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-col>
              </fragment>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>

    <!-- ADD CHANGE NOM -->
    <v-dialog
      v-model="isAddChange"
      scrollable
      max-width="380px"
    >
      <v-card max-height="400px">
        <v-card-title>{{ `${$t('btn.insert')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          {{
            $t('msg.addChange', {
              n: model.name ? model.name : '',
            })
          }}
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isAddChange = !isAddChange"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="addChange"
            @click="confirmAddChange()"
          >
            {{ $t('btn.send') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- CHANGE SERVICIOS -->
    <v-dialog
      v-model="isChangeServicio"
      scrollable
      max-width="450px"
    >
      <v-card max-height="450px">
        <v-card-title>{{ `${$t('lbl.gestionChange')}` }}</v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row
            v-if="model.name"
            class="mt-5"
          >
            <v-col
              cols="12"
              md="10"
            >
              <p class="mt-2">
                <b>{{ $t('lbl.service') }}</b>: {{ model.name }}
              </p>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-tooltip
                top
                color="primary"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    fab
                    small
                    v-bind="attrs"
                    color="primary"
                    v-on="on"
                    @click="showAdd('servicios')"
                  >
                    <v-icon>{{ icons.mdiPlus }}</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t('btn.insert') }}</span>
              </v-tooltip>
            </v-col>

            <v-col
              cols="12"
              md="12"
            >
              <v-autocomplete
                v-model="model.id"
                :items="itemsServicios"
                :search-input.sync="searchServicios"
                hide-details
                hide-selected
                :label="$t('menu.servicios')"
                outlined
                dense
                item-text="name"
                item-value="id"
              >
                <template v-slot:no-data>
                  <v-list-item>
                    <v-list-item-title>
                      {{ $t('menu.servicios') }}
                    </v-list-item-title>
                  </v-list-item>
                </template>
                <template v-slot:selection="{ item }">
                  <span v-text="item.name"></span>
                </template>
                <template v-slot:item="{ item }">
                  <v-list-item-content>
                    <v-list-item-title>{{ item.name }}</v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            outlined
            @click="isChangeServicio = !isChangeServicio"
          >
            {{ $t('btn.close') }}
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            outlined
            :loading="changing"
            @click="confirmChange('servicios')"
          >
            {{ $t('btn.acceptChange') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import useAppConfig from '@core/@app-config/useAppConfig'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiPlaylistEdit,
  mdiCancel,
  mdiInformationOutline,
} from '@mdi/js'

export default {
  components: {
    AppCardCode,
  },
  setup() {
    const { menuIsVerticalNavMini } = useAppConfig()

    return {
      menuIsVerticalNavMini,
    }
  },
  data() {
    return {
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiPlaylistEdit,
        mdiCancel,
        mdiInformationOutline,
      },
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      transmisiones: [],
      marcas: [],
      combustibles: [],
      caracteristicas: [],
      modelCaracteristicas: [],
      model: {},
      edad_min: {},
      loading: false,

      items: [],
      typeAuto: [],
      itemsTypeAuto: [],
      searchTypeAuto: null,

      isDialogVisible: false,
      isDialogVisibleDelete: false,
      nameOlds: [],
      newNameOld: {},
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      itemsOperadores: [],
      allOperadores: [],
      searchOperador: null,

      itemsMarcas: [],
      allMarcas: [],
      searchMarcas: null,

      categories: [],
      itemsTipoDestino: [],
      allTipoDestino: [],
      searchTipoDestino: null,
      intereses: [],
      modelIntereses: [],
      servicios: [],
      itemsServicios: [],
      searchServicios: null,
      galery: [],
      editGeoTag: true,
      address: {},
      edades: [],
      numeros: [],
      file: null,
      uploadingFile: false,
      processUpload: false,
      cancelarUpload: false,
      isChangeServicio: false,
      isAddChange: false,
      addChange: false,
      type: null,
      changing: false,
      lote: null,
      posItem: -1,
      posService: -1,
    }
  },
  computed: {
    ...mapState({
      addressHotel: state => state.app.addressHotel,
      onlyShow: state => state.app.onlyShow,
    }),
  },
  watch: {
    searchServicios(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterServicios(val.toLowerCase())
    },
  },
  mounted() {
    // PARA Q NAZCA EL MENU IZQ OCULTO
    this.menuIsVerticalNavMini = true
  },
  created() {
    this.getServicios()

    this.getLote()
    if (localStorage.getItem('hotels-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }

    if (localStorage.getItem('index-service-hotel-lote') !== null) {
      this.posItem = localStorage.getItem('index-service-hotel-lote')
    } else {
      this.$router.push({ name: 'hotels-upload-lote' })
    }
  },
  methods: {
    ...mapMutations([
      'adicionarImgHotels',
      'updateImgHotels',
      'adicionarContactHotel',
      'updateContactHotel',
      'updateAddressHotel',
      'updateGeoTag',
      'setOnlyShow',
    ]),
    changeSugerido() {
      if (!this.model.sugerido) {
        this.model.number_sugerido = null
      }
    },
    filterServicios(v) {
      this.itemsServicios = []
      if (v === '') {
        this.itemsServicios = []
      } else {
        this.itemsServicios = this.servicios.filter(e => e.name.toLowerCase())
      }
    },

    getLote() {
      this.axios
        .get('lotes/hotels', {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          console.log(res)
          this.lote = res.data.data.data
        })
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.isLoading = false))
    },
    modalServicio(item, pos) {
      this.model = item
      this.posService = pos
      this.isChangeServicio = true
    },
    confirmChange(type) {
      this.axios
        .put(
          `lotes/update-service/${this.lote.id}`,
          {
            type,
            posService: this.posService,
            idHotel: this.lote.lote[this.posItem].id,
            changes: this.model,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },
    showAdd(type) {
      this.type = type
      this.isAddChange = true
    },
    confirmAddChange() {
      this.addChange = true
      this.axios
        .post(
          `lotes/add-change-service/${this.lote.id}`,
          {
            type: this.type,
            posService: this.posService,
            idHotel: this.lote.lote[this.posItem].id,
            changes: this.model,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
            },
          },
        )
        // eslint-disable-next-line no-unused-vars
        .then(response => {
          this.$toast.success(this.$t('msg.infoSuccess'))
          this.getLote()
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => this.resetFile())
    },

    getServicios() {
      this.axios
        .get(`nom_servicios_hotels?per_page=1000&perfil_slug=${localStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.servicios = res.data.data
        })
    },
    resetFile() {
      this.file = null
      this.model = {}
      this.type = null
      this.uploadingFile = false
      this.cancelarUpload = false
      this.isDialogVisibleDelete = false
      this.isAddChange = false
      this.addChange = false
      this.posService = -1
      this.isChangeServicio = false
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
