var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('v-row',{staticClass:"match-height"},[_c('v-col',{attrs:{"cols":"12"}},[_c('app-card-code',{attrs:{"title":((_vm.$t('menu.products')) + " / " + (_vm.$t('menu.hotels')) + " / " + (_vm.$t('lbl.cargaLote')) + " / " + (_vm.$t('menu.servicios')))}},[_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-btn',{staticClass:"mb-4 me-3",attrs:{"color":"error"},on:{"click":function($event){return _vm.$router.push({ name: 'hotels-upload-lote' })}}},[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiArrowLeft))]),_c('span',[_vm._v(_vm._s(_vm.$t('btn.back')))])],1)],1)],1),_c('v-form',{staticClass:"multi-col-validation"},[(_vm.lote !== null)?_c('v-row',[(_vm.lote !== null)?_c('fragment',[_c('v-col',{attrs:{"cols":"12","md":"8","offset-md":"2"}},[_c('v-simple-table',{scopedSlots:_vm._u([{key:"default",fn:function(){return [_c('thead',[_c('tr',[_c('th',{staticClass:"text-uppercase"},[_vm._v(" "+_vm._s(_vm.$t('lbl.service'))+" ")])])]),_c('tbody',_vm._l((_vm.lote.lote[_vm.posItem].servicios),function(item,index){return _c('tr',{key:index},[(!item.listo)?_c('fragment',[_c('td',[_vm._v(" "+_vm._s(item.name)+" "),(item.id === 0 && item.name !== '')?_c('v-tooltip',{attrs:{"top":"","color":"error"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""},on:{"click":function($event){return _vm.modalServicio(item, index)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiInformationOutline)+" ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('lbl.inexistServicio')))])]):_vm._e()],1)]):_vm._e()],1)}),0)]},proxy:true}],null,false,2877467391)})],1)],1):_vm._e()],1):_vm._e()],1)],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"380px"},model:{value:(_vm.isAddChange),callback:function ($$v) {_vm.isAddChange=$$v},expression:"isAddChange"}},[_c('v-card',{attrs:{"max-height":"400px"}},[_c('v-card-title',[_vm._v(_vm._s(("" + (_vm.$t('btn.insert')))))]),_c('v-divider'),_c('v-card-text',[_vm._v(" "+_vm._s(_vm.$t('msg.addChange', { n: _vm.model.name ? _vm.model.name : '', }))+" ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isAddChange = !_vm.isAddChange}}},[_vm._v(" "+_vm._s(_vm.$t('btn.close'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","loading":_vm.addChange},on:{"click":function($event){return _vm.confirmAddChange()}}},[_vm._v(" "+_vm._s(_vm.$t('btn.send'))+" ")])],1)],1)],1),_c('v-dialog',{attrs:{"scrollable":"","max-width":"450px"},model:{value:(_vm.isChangeServicio),callback:function ($$v) {_vm.isChangeServicio=$$v},expression:"isChangeServicio"}},[_c('v-card',{attrs:{"max-height":"450px"}},[_c('v-card-title',[_vm._v(_vm._s(("" + (_vm.$t('lbl.gestionChange')))))]),_c('v-divider'),_c('v-card-text',[(_vm.model.name)?_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12","md":"10"}},[_c('p',{staticClass:"mt-2"},[_c('b',[_vm._v(_vm._s(_vm.$t('lbl.service')))]),_vm._v(": "+_vm._s(_vm.model.name)+" ")])]),_c('v-col',{attrs:{"cols":"12","md":"2"}},[_c('v-tooltip',{attrs:{"top":"","color":"primary"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"fab":"","small":"","color":"primary"},on:{"click":function($event){return _vm.showAdd('servicios')}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiPlus))])],1)]}}],null,false,2840385893)},[_c('span',[_vm._v(_vm._s(_vm.$t('btn.insert')))])])],1),_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.itemsServicios,"search-input":_vm.searchServicios,"hide-details":"","hide-selected":"","label":_vm.$t('menu.servicios'),"outlined":"","dense":"","item-text":"name","item-value":"id"},on:{"update:searchInput":function($event){_vm.searchServicios=$event},"update:search-input":function($event){_vm.searchServicios=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.$t('menu.servicios'))+" ")])],1)]},proxy:true},{key:"selection",fn:function(ref){
var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(item.name)}})]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(item.name))])],1)]}}],null,false,169867733),model:{value:(_vm.model.id),callback:function ($$v) {_vm.$set(_vm.model, "id", $$v)},expression:"model.id"}})],1)],1):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","outlined":""},on:{"click":function($event){_vm.isChangeServicio = !_vm.isChangeServicio}}},[_vm._v(" "+_vm._s(_vm.$t('btn.close'))+" ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","outlined":"","loading":_vm.changing},on:{"click":function($event){return _vm.confirmChange('servicios')}}},[_vm._v(" "+_vm._s(_vm.$t('btn.acceptChange'))+" ")])],1)],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }